import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const contact = () => {
  return (
    <Layout>
      <SEO
        title="Player Skills in PES 2021"
        description="Here is the complete list of Player Skills available in PES 2021."
      />
      <div className="container">
        <h1>Player Skills in PES 2021</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Player Skills</li>
        </ul>
        <p>
          Player Skills were first introduced in 2014 (just like{" "}
          <Link to="/playing-styles/">Playing Styles</Link>) and are very
          important in 2 ways: it's what set players apart and very useful when
          it comes to{" "}
          <Link to="/signing-new-players-scout-combinations/">
            scouting for a specific player
          </Link>
          .
        </p>
        <p>
          In PES 2020, 3 new skills were introduced: "Long Range Shooting",
          "Through Passing" and "Gamesmanship" (formerly known in PES 2019 as
          "Malicia"). All remain available in PES 2021 - Season Update.
        </p>
        <h2>Player Skills - The Complete List</h2>
        <p>
          In PES 2021, there are 41 different Player Skills. This is how Konami
          describes each one.
        </p>
        <h3 className="bullet">Scissors Feint</h3>
        <p>Enables players to execute the Scissors Feint at high speed.</p>
        <h3 className="bullet">Double Touch</h3>
        <p>Enables players to quickly execute the Double Touch.</p>
        <h3 className="bullet">Flip Flap</h3>
        <p>Enables players to execute the Flip Flap.</p>
        <h3 className="bullet">Marseille Turn</h3>
        <p>Enables players to execute the Marseille Turn.</p>
        <h3 className="bullet">Sombrero</h3>
        <p>
          Enables players to execute the Boomerang Trap and the Crescent Turn.
        </p>
        <h3 className="bullet">Cross Over Turn</h3>
        <p>Enables players to quickly execute the Cross Over Turn.</p>
        <h3 className="bullet">Cut Behind & Turn</h3>
        <p>Enables players to execute the Cut Behind.</p>
        <h3 className="bullet">Scotch Move</h3>
        <p>Enables players to execute the Scotch Move.</p>
        <h3 className="bullet">Step On Skill control</h3>
        <p>Enables step on ball control to execute feints and turns.</p>
        <h3 className="bullet">Heading</h3>
        <p>
          Improves the accuracy of headers as well as the frequency of downward
          headers.
        </p>
        <h3 className="bullet">Long Range Drive</h3>
        <p>
          Enables players to hit curling shots with great accuracy from a
          distance.
        </p>
        <h3 className="bullet">Chip shot control</h3>
        <p>Enables accurate chip shots.</p>
        <h3 className="bullet">Long Range Shooting</h3>
        <p>A measure of how accurately the player can shoot at long range.</p>
        <h3 className="bullet">Knuckle Shot</h3>
        <p>
          Enables players to hit knuckle shots with greater ease, also applies
          to Free Kicks.
        </p>
        <h3 className="bullet">Dipping Shot</h3>
        <p>
          Enables top spin shots that bounce erratically in front of the
          goalkeeper.
        </p>
        <h3 className="bullet">Rising Shots</h3>
        <p>Enables shots that begin with a low trajectory that rise sharply.</p>
        <h3 className="bullet">Acrobatic Finishing </h3>
        <p>
          Enables players to find a finish even from from awkward positions or
          when off balance.
        </p>
        <h3 className="bullet">Heel Trick </h3>
        <p>
          Enables players to pass and shoot using the heel, even from awkward
          positions or when off balance.
        </p>
        <h3 className="bullet">First-time Shot</h3>
        <p>Improves technique and precision when taking first-time shots.</p>
        <h3 className="bullet">One-touch Pass</h3>
        <p>Improves technique and precision when playing one-touch passes.</p>
        <h3 className="bullet">Through Passing</h3>
        <p>Increases the accuracy of a player's through balls.</p>
        <h3 className="bullet">Weighted Pass</h3>
        <p>
          Enables players to apply back-spin when playing lofted passes and
          through balls improving accuracy.
        </p>
        <h3 className="bullet">Pinpoint Crossing</h3>
        <p>Enables players to curl in crosses with great accuracy.</p>
        <h3 className="bullet">Outside Curler</h3>
        <p>
          Enables players to shoot and pass using the outside of the boot even
          at long distances.
        </p>
        <h3 className="bullet">Rabona</h3>
        <p>
          Enables players to execute a Rabona and means they can pass or shoot
          when the opposition is not expecting it.
        </p>
        <h3 className="bullet">No Look Pass</h3>
        <p>Enables passes that misdirect opponents.</p>
        <h3 className="bullet">Low Lofted Pass</h3>
        <p>
          Enables players to hit a long accurate Lofted Pass with a low
          trajectory when appropriate.
        </p>
        <h3 className="bullet">GK Low Punt</h3>
        <p>
          Enables players to take long accurate punt kicks with a low
          trajectory.
        </p>
        <h3 className="bullet">GK High Punt</h3>
        <p>
          Enables goalkeepers to take long, high punt kicks that end up deep in
          opposition territory.
        </p>
        <h3 className="bullet">Long Throw</h3>
        <p>Improves the range of long throws.</p>
        <h3 className="bullet">GK Long Throw</h3>
        <p>Improves the range on throws by the goalkeeper.</p>
        <h3 className="bullet">Penalty Specialist</h3>
        <p>Enables higher accuracy penalty kicks.</p>
        <h3 className="bullet">GK Penalty Saver</h3>
        <p>Enables better goalkeeping reactions against penalty kicks.</p>
        <h3 className="bullet">Gamesmanship</h3>
        <p>Enables players to win free kicks when on the ball.</p>
        <h3 className="bullet">Man Marking</h3>
        <p>
          Enables a player to stick to and persistently chase down an opponent.
        </p>
        <h3 className="bullet">Track Back</h3>
        <p>
          Enables an offensive player to actively pressure the opposition player
          who is on the ball to try to win it back.
        </p>
        <h3 className="bullet">Interception</h3>
        <p>Enables better interception skills.</p>
        <h3 className="bullet">Acrobatic Clear</h3>
        <p>
          Enables players to clear the ball using their feet, even from awkward
          positions.
        </p>
        <h3 className="bullet">Captaincy</h3>
        <p>
          Become the team's inspiration on the pitch, reducing the effects of
          fatigue for all the team.
        </p>
        <h3 className="bullet">Super-sub</h3>
        <p>
          Improves player's abilities when introduced in the second half of the
          game.
        </p>
        <h3 className="bullet">Fighting Spirit</h3>
        <p>
          Enables players to perform better under pressure and fatigue, as well
          as adversity.
        </p>
      </div>
    </Layout>
  )
}

export default contact
